import { useQuery } from "react-query";
import request from "../request";
import { useGetClientProfile } from "../queries/use-get-client-profile";
import { useAppSelector } from "../../redux/hook";

const useGetCorpClientProfile = () => {
  const { corp_id, isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["corp-client-profile", corp_id],
    queryFn: () =>
      request.private
        .get<{
          id: number;
          name: string;
          balance: number;
          self_created: boolean;
          commission_rate: null;
          created_date: string;
          clients_count: number;
          debt?: number | null;
        }>(`/v1/corporates/${corp_id}/profile`)
        .then((res) => res.data),
    enabled: !!corp_id && isAuthenticated,
  });
};

export default useGetCorpClientProfile;
