import { useQuery } from "react-query";
import { useAppSelector } from "../../redux/hook";
import { Coordinate } from "../../types/global.types";
import request from "../request";

interface OrderRouteType {
  status: string | null;
  data: {
    object: {
      points: Coordinate[];
    };
  };
}

export const useGetClientOrderRoute = (id?: string | null) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery(
    ["order_route", id],
    () =>
      request.private
        .get<OrderRouteType>(`v1/orders/${id}/route`)
        .then((res) => res.data),
    { enabled: !!id && isAuthenticated }
  );
};
