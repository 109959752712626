import { Box, ButtonBase } from "@mui/material";
import { useState } from "react";
import useGetCorpProfile from "../../../services/corp-panel/use-get-corp-profile";
import MobileDrawer from "../../../components/mobile/mobile-drawer/mobile-drawer";
import BuildingIcon from "../../../components/icons/building";
import useGetCorporateList from "../../../services/corp-panel/use-get-corporate-list";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { setCorpId } from "../../../redux/slices/auth-slice";

const CompanySwitchDrawer = () => {
  const appDispatch = useAppDispatch();
  const { corp_id } = useAppSelector((state) => state.auth);

  const [open, setOpen] = useState(false);

  const corpProfile = useGetCorpProfile();
  const companiesList = useGetCorporateList();

  return (
    <>
      <ButtonBase onClick={() => setOpen(true)}>
        {corpProfile?.data?.name}
      </ButtonBase>

      <MobileDrawer
        open={open}
        onClose={() => setOpen(false)}
        drawerTitle={t("companies").toString()}
        color="gray"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 2.5,
            bgcolor: "#fff",
            borderRadius: 1,
            px: 1.5,
          }}
        >
          <ButtonBase
            sx={{
              minHeight: "52px",
              borderBottom: "1px solid #F3F3F3",
              color: "#242424",
              fontSize: 17,
              fontWeight: 500,
              ":last-child": {
                borderBottom: "none",
              },
              gap: "10px",
            }}
          >
            <BuildingIcon color="#007AFF" /> {corpProfile?.data?.name}
          </ButtonBase>
          {companiesList?.data?.corporates
            ?.filter((company) => company?.id !== corpProfile?.data?.id)
            ?.map((company) => (
              <ButtonBase
                sx={{
                  minHeight: "52px",
                  borderBottom: "1px solid #F3F3F3",
                  color: "#242424",
                  fontSize: 17,
                  fontWeight: 500,
                  ":last-child": {
                    borderBottom: "none",
                  },
                  gap: "10px",
                }}
                onClick={() => appDispatch(setCorpId(company?.id))}
              >
                <BuildingIcon /> {company?.name}
              </ButtonBase>
            ))}
        </Box>
      </MobileDrawer>
    </>
  );
};

export default CompanySwitchDrawer;
