import { Children, createContext, ReactNode, useMemo, useReducer } from "react";
import { useAuthReducer } from "./auth-reducer";
import { AuthActions, initialStateType } from "./auth-types";

interface AuthContextType extends initialStateType {
  dispatch: React.Dispatch<AuthActions>;
}

export const AuthCtx = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: React.FC<{ children: any }> = ({ children }) => {
  const [state, dispatch] = useAuthReducer();

  const value = { state, dispatch };

  const stateValues = useMemo(() => ({ dispatch, ...state }), [
    state,
    dispatch,
  ]);

  return <AuthCtx.Provider value={stateValues}>{children}</AuthCtx.Provider>;
};

export default useAuthReducer;
