import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  ButtonBase,
  FilledInput,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useGetClientProfile } from "../../../services/queries/use-get-client-profile";
import useAddEmployee from "../../../services/corp-panel/use-add-employee";
import useModal from "../../../hooks/use-modal";
import { priceFromatter } from "../../../utils/price-formatter";
import { numberCleaner } from "../../../utils/number-formatter";
import useGetClientDetail from "../../../services/corp-panel/use-get-client-detail";
import theme from "../../../configs/theme.config";

// Styles
const activeButtonSx = {
  border: `1px solid #FBCC14`,
  color: "#FBCC14",
};

const inactiveButtonSx = {
  bgcolor: "#F8F8F8",
  color: theme.palette.text.primary,
};

// Constants
const WEEKDAYS = [1, 2, 3, 4, 5, 6, 7] as const;

const defaultFormValues: Partial<FormTypes> = {
  repeatability: "unlimit",
  weekdays: [],
  start: new Date(new Date().setHours(8, 0, 0, 0)),
  end: new Date(new Date().setHours(18, 0, 0, 0)),
};

type Repeatability = "month" | "one" | "unlimit";

interface FormTypes {
  amount: number;
  repeatability: Repeatability;
  weekdays: number[];
  start: Date;
  end: Date;
}

const EditEmployeeDetail: React.FC<{ client_id?: number }> = ({
  client_id,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const amountLimit = useModal();
  const tarifRestrictions = useModal();

  const profile = useGetClientProfile();
  const addEmployee = useAddEmployee();

  const clientDetail = useGetClientDetail(client_id);

  const formSchema = useMemo(() => {
    const schema = {
      amount: yup.number().nullable(),
      repeatability: yup.string().nullable(),
      weekdays: yup.array().nullable(),

      start: yup.date().nullable(),
      end: yup.date().nullable(),
    };

    if (amountLimit?.isOpen) {
      schema.amount = yup
        .number()
        .min(1000, t("validation.amount_limit_min", { min: 1000 }))
        .required(t("validation.amount_limit_required"));

      schema.repeatability = yup
        .string()
        .oneOf(
          ["month", "one", "unlimit"],
          t("validation.invalid_repeatability")
        )
        .required(t("validation.repeatability_required"));
    }

    if (tarifRestrictions?.isOpen) {
      schema.start = yup.date().required(t("validation.start_time_required"));
      schema.end = yup.date().required(t("validation.end_time_required"));
      schema.weekdays = yup
        .array()
        .of(yup.number().min(1).max(7))
        .required(t("validation.weekdays_required"));
    }

    return yup.object().shape(schema);
  }, [amountLimit?.isOpen, tarifRestrictions?.isOpen, t]);

  const form = useForm<FormTypes>({
    resolver: yupResolver(formSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (amountLimit?.isOpen) {
      form.setValue("amount", 0);
      return form.setValue("repeatability", "month");
    }

    return form.setValue("repeatability", "unlimit");
  }, [amountLimit?.isOpen]);

  const handleSubmit = (values: FormTypes) => {
    if (!profile?.data?.comp_id) return;

    addEmployee.mutate({
      phone: clientDetail?.data?.phone || "",
      amount: values.amount || undefined,
      repeatability: values.repeatability,
      is_manager: false,
      author_id: profile.data.comp_id,
      author_type: "corp",
    });
  };

  const toggleWeekday = (day: number) => {
    const currentDays = form.watch("weekdays") || [];

    const newDays = currentDays.includes(day)
      ? currentDays.filter((d) => d !== day)
      : [...currentDays, day];

    form.setValue("weekdays", newDays);
  };

  return (
    <Stack component="form" onSubmit={form.handleSubmit(handleSubmit)}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1.25,
          mt: 2.5,
        }}
      >
        <Switch
          checked={amountLimit?.isOpen}
          onChange={amountLimit?.toggle}
          id="employee-amount-limit"
        />
        <Box>
          <InputLabel
            htmlFor="employee-amount-limit"
            sx={{ cursor: "pointer" }}
          >
            {t("Amount limit")}
          </InputLabel>
          <FormHelperText>
            {t("Only the entered amount can be used")}
          </FormHelperText>
        </Box>
      </Box>
      {amountLimit?.isOpen && (
        <>
          <Controller
            name="amount"
            control={form.control}
            render={({ field }) => {
              return (
                <Box mb={2.5} mt={2} width="100%">
                  <FilledInput
                    placeholder={t("Enter amount")}
                    sx={{}}
                    type="string"
                    value={priceFromatter(field?.value || "").trim()}
                    onChange={(e) =>
                      field?.onChange(numberCleaner(e.target.value || ""))
                    }
                    fullWidth
                  />
                  <FormHelperText
                    error={!!form?.formState?.errors?.amount?.message}
                  >
                    {form?.formState?.errors?.amount?.message}
                  </FormHelperText>
                </Box>
              );
            }}
          />
          <InputLabel sx={{ mb: 1 }}>{t("repeatability")}</InputLabel>
          <Controller
            control={form.control}
            name="repeatability"
            render={({ field }) => (
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={field?.value}
                  data-sentry-mask
                  sx={{ width: "100%" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ".MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontWeight: 500,
                      },
                    }}
                  >
                    <Box
                      component="label"
                      htmlFor="one"
                      sx={{
                        p: "5px 16px",
                        borderRadius: "12px",
                        bgcolor: theme.palette.secondary.main,
                        ":hover": { bgcolor: theme.palette.secondary.dark },
                        width: "100%",
                        cursor: "pointer",
                        mr: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FormControlLabel
                        value="one"
                        control={
                          <Radio
                            id="one"
                            color="info"
                            {...form.register("repeatability")}
                          />
                        }
                        label={t(`repeatability.one`)}
                      />
                    </Box>
                    <Box
                      component="label"
                      htmlFor="month"
                      sx={{
                        p: "5px 16px",
                        borderRadius: "12px",
                        bgcolor: theme.palette.secondary.main,
                        ":hover": { bgcolor: theme.palette.secondary.dark },
                        width: "100%",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FormControlLabel
                        value="month"
                        control={
                          <Radio
                            id="month"
                            color="info"
                            {...form.register("repeatability")}
                          />
                        }
                        label={t(`repeatability.month`)}
                      />
                    </Box>
                  </Box>
                </RadioGroup>
                <FormHelperText
                  error={!!form?.formState?.errors?.repeatability?.message}
                >
                  {form?.formState?.errors?.repeatability?.message}
                </FormHelperText>
              </Box>
            )}
          />
        </>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1.25,
          mt: 2.5,
        }}
      >
        <Switch
          checked={tarifRestrictions?.isOpen}
          onChange={tarifRestrictions?.toggle}
          id="employee-amount-limit"
        />
        <Box>
          <InputLabel
            htmlFor="employee-amount-limit"
            sx={{ cursor: "pointer" }}
          >
            {t("Tariff restrictions")}
          </InputLabel>
          <FormHelperText>
            {t("The service can be used during this period")}
          </FormHelperText>
        </Box>
      </Box>
      {tarifRestrictions?.isOpen && (
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
            <Controller
              name="start"
              control={form.control}
              render={({ field }) => {
                return (
                  <Box width="100%">
                    <TimePicker
                      value={field?.value}
                      onChange={(newValue) => {
                        field?.onChange(newValue);
                      }}
                      renderInput={({ placeholder, ...params }) => (
                        <TextField
                          fullWidth
                          placeholder={placeholder || t("From")}
                          {...params}
                          variant="filled"
                        />
                      )}
                    />
                    <FormHelperText
                      error={!!form?.formState?.errors?.start?.message}
                    >
                      {form?.formState?.errors?.start?.message}
                    </FormHelperText>
                  </Box>
                );
              }}
            />
            <Controller
              name="end"
              control={form.control}
              render={({ field }) => {
                return (
                  <Box width="100%">
                    <TimePicker
                      value={field?.value}
                      onChange={(newValue) => {
                        field?.onChange(newValue);
                      }}
                      renderInput={({ placeholder, ...params }) => (
                        <TextField
                          fullWidth
                          placeholder={placeholder || t("To")}
                          {...params}
                          variant="filled"
                        />
                      )}
                    />
                    <FormHelperText
                      error={!!form?.formState?.errors?.end?.message}
                    >
                      {form?.formState?.errors?.end?.message}
                    </FormHelperText>
                  </Box>
                );
              }}
            />
          </Box>
          <InputLabel sx={{ mb: 1, mt: 1.5 }}>{t("repeatability")}</InputLabel>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflowX: "auto",
              gap: 0.75,
            }}
          >
            {WEEKDAYS.map((day) => (
              <ButtonBase
                key={day}
                onClick={() => toggleWeekday(day)}
                sx={{
                  borderRadius: "10px",
                  minWidth: "44px",
                  minHeight: "44px",
                  ...(form.watch("weekdays")?.includes(day)
                    ? activeButtonSx
                    : inactiveButtonSx),
                }}
              >
                {t(`weekdays.short.${day}`)}
              </ButtonBase>
            ))}
          </Box>
          <FormHelperText
            error={!!form?.formState?.errors?.weekdays?.message}
            sx={{ mt: 0.5 }}
          >
            {form?.formState?.errors?.weekdays?.message}
          </FormHelperText>
        </>
      )}

      <Button sx={{ mb: 2.5, mt: 2.5 }} type="submit">
        {t("save")}
      </Button>
    </Stack>
  );
};

export default EditEmployeeDetail;
