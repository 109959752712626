import { Box, BoxProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import ArrowRight from "../icons/arrow-right";
import { MenuListItemWrapper } from "./styles";

interface MenuListItemType extends BoxProps {
  startIcon?: any;
  endIcon?: any;
  arrowRight?: boolean;
}

const MenuListItem: React.FC<MenuListItemType> = ({
  children,
  startIcon,
  endIcon,
  arrowRight,
  ...props
}) => (
  <MenuListItemWrapper component="a" {...props}>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {startIcon && (
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          mr={(theme) => theme.spacing(2)}
        >
          {startIcon}
        </Box>
      )}
      {children}
    </Box>
    {endIcon && <Box ml={(theme) => theme.spacing(3)}>{endIcon}</Box>}
    {arrowRight && (
      <Box ml={(theme) => theme.spacing(3)}>
        <ArrowRight />
      </Box>
    )}
  </MenuListItemWrapper>
);

export default MenuListItem;
