import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Modal } from "../../../../components/modal/modal";
import theme from "../../../../configs/theme.config";
import useAuthModalOpener from "../../../../hooks/use-auth-modal-opener";
import CanceledOrders from "./canceled";
import FinishedOrders from "./finished";
import OrderHistoryDetail from "./order-history-detail";

const OrderHistoryModal = () => {
  const { t } = useTranslation();
  const [state, set] = useSearchParams();
  const id = state.get("id");

  const tabs = [
    {
      title: t("finished"),
      type: "finished",
      component: FinishedOrders,
    },
    {
      title: t("canceled"),
      type: "cancelled",
      component: CanceledOrders,
    },
  ];

  useAuthModalOpener({ redirection: true });

  return (
    <Modal
      isOpen={!!state.get("history")}
      onClose={() => set({})}
      closeIcon={true}
      style={{ padding: 0, width: "400px" }}
      overlayStyle={{ zIndex: 101 }}
    >
      <Box
        sx={{
          borderRadius: "18px",
          overflow: "auto",
          height: "80vh",
          "::-webkit-scrollbar": {
            width: "0px",
          },
        }}
      >
        {!id ? (
          <>
            <Box
              sx={{
                boxShadow: "-15px 8px 30px rgba(69, 69, 69, 0.08)",
                px: 2.5,
                pt: 2.5,
                pb: 1.5,
                bgcolor: theme.palette.background.paper,
                borderRadius: "18px 18px 0 0",
              }}
            >
              <Typography variant="h2" fontSize="23px" mb={2}>
                {t("myTrips")}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  bgcolor: theme.palette.background.default,
                  p: 0.5,
                  borderRadius: "12px",
                  overflow: "auto",
                  scrollbarWidth: 0,
                  "::-webkit-scrollbar": {
                    height: "0px",
                  },
                }}
              >
                {tabs.map((tab) => (
                  <ButtonBase
                    key={tab.type}
                    sx={
                      tab.type === state.get("history")
                        ? {
                            bgcolor: theme.palette.background.paper,
                            p: 1,
                            borderRadius: "8px",
                            width: "100%",
                            boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.12);",
                          }
                        : {
                            p: 1,
                            borderRadius: "8px",
                            width: "100%",
                          }
                    }
                    onClick={() => set({ history: tab.type })}
                  >
                    <Typography lineHeight="20px">{tab.title}</Typography>
                  </ButtonBase>
                ))}
              </Stack>
            </Box>
            <Box height="400px" sx={{ p: 2.5 }}>
              {tabs.map(
                (tab) =>
                  tab.type === state.get("history") && (
                    <tab.component key={tab.type} />
                  )
              )}
            </Box>
          </>
        ) : (
          <OrderHistoryDetail orderID={+id} />
        )}
      </Box>
    </Modal>
  );
};

export default OrderHistoryModal;
