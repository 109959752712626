import { useQuery } from "react-query";
import request from "../request";
import { useAppSelector } from "../../redux/hook";

const useGetCorporateList = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["corporates-list"],
    queryFn: () =>
      request.private
        .get<{
          page: number;
          total_page: number;
          count: number;
          corporates: Array<{
            id: number;
            name?: string;
            phone?: string;
            pinfl?: string;
            inn?: string;
            account_number?: string;
            date_reg?: string;
            email?: string;
            address?: string;
            billing?: boolean;
            is_blocked?: boolean;
            self_created?: boolean;
            clients_count?: number;
            corp_manager_id?: number;
            corp_manager_name?: string;
            agreement_number?: string;
            agreement_date?: string;
            contract_id?: null;
          }>;
        }>(`/v1/corporates?page=1`)
        .then((res) => res.data),
    enabled: isAuthenticated,
  });
};

export default useGetCorporateList;
