import { useQuery } from "react-query";
import request from "../request";
import { useAppSelector } from "../../redux/hook";

const useGetClientRestrictions = (client_id: number | undefined) => {
  const { corp_id, isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["client-restrictions", corp_id, client_id],
    queryFn: () =>
      request.private
        .get(
          `/v1/corporates/${corp_id}/clients/${client_id}/client-restrictions`
        )
        .then((res) => res?.data),
    enabled: isAuthenticated && !!corp_id && !!client_id,
  });
};

export default useGetClientRestrictions;
