import { useQuery } from "react-query";
import request from "../request";
import { useAppSelector } from "../../redux/hook";

const useGetClientDetail = (client_id: number | undefined) => {
  const { corp_id, isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["client-detail", corp_id, client_id],
    queryFn: () =>
      request.private
        .get<{
          id: number;
          name: string;
          phone: string;
          balance: number;
          amount: number;
          is_manager: boolean;
          repeatability: "month" | "one" | "unlim";
          updated_time: string;
          last_activity: string;
          corp_self_created: boolean;
        }>(`/v1/corporates/${corp_id}/clients/${client_id}/profile`)
        .then((res) => res?.data),
    enabled: isAuthenticated && !!corp_id && !!client_id,
  });
};

export default useGetClientDetail;
