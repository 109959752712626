import { useInfiniteQuery } from "react-query";
import { useAppSelector } from "../../redux/hook";
import request from "../request";
import { CorpOrderType } from "./use-get-corp-orders";
import { useGetClientProfile } from "../queries/use-get-client-profile";

export interface OrderHistoryType {
  total_count: number;
  orders: Array<CorpOrderType>;
  next?: boolean;
}

export const useGetCorpInfiniteOrders = () => {
  const { isAuthenticated, corp_id } = useAppSelector((state) => state.auth);

  const fetchProjects = ({ pageParam = 0 }) =>
    request.private
      .get<OrderHistoryType>(
        `/v1/corporates/${corp_id}/orders?page=${pageParam ?? 1}`
      )
      .then((res) => res.data);

  return useInfiniteQuery(["order-history-infinite"], fetchProjects, {
    getNextPageParam: (lastPage, pages) =>
      lastPage?.next ? pages.length : undefined,
    enabled: isAuthenticated && !!corp_id,
  });
};
