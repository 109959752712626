import { useQuery } from "react-query";
import request from "../request";
import { CorpOrderType } from "./use-get-corp-orders";
import { useAppSelector } from "../../redux/hook";

const useGetTodaysOrder = () => {
  const { corp_id, isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["todays-orders", corp_id],
    queryFn: () =>
      request.private
        .get<{ orders: Array<CorpOrderType> }>(
          `/v1/corporates/${corp_id}/orders/today`
        )
        .then((res) => res.data),
    enabled: !!corp_id && isAuthenticated,
  });
};

export default useGetTodaysOrder;
