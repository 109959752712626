import { Box, CircularProgress, Typography } from "@mui/material";
import { ChevronRight, FlagIcon } from "lucide-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useGetTodaysOrder from "../../services/corp-panel/use-get-todays-orders";
import { priceFromatter } from "../../utils/price-formatter";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const Wrapper = styled.div`
  border-radius: 20px 20px 0 0;
  background-color: #ffffff;
  flex: 1;
  padding: 10px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: 6px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 0 12px;

  & > div:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
`;

const LastTrips = () => {
  const { t } = useTranslation();
  const todaysOrders = useGetTodaysOrder();

  return (
    <Wrapper>
      <Header>
        <Typography fontSize={21} fontWeight={600}>
          {t("today")}
        </Typography>
        <Link
          to="/mobile/corp/history"
          style={{
            display: "flex",
            alignItems: "center",
            color: "#2F80ED",
          }}
        >
          <Typography
            fontSize={17}
            fontWeight={500}
            lineHeight={3}
            color="#2F80ED"
          >
            {t("history")}
          </Typography>
          <ChevronRight />
        </Link>
      </Header>
      <ListWrapper>
        {todaysOrders?.data?.orders?.map((order, index) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: 1,
              width: "100%",
              justifyContent: "space-between",
              py: 1.5,
            }}
          >
            <div>
              <Typography>{order?.client_name}</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FlagIcon
                  style={{
                    width: 16,
                    minWidth: 16,
                    height: 16,
                    minHeight: 16,
                    color: "#999999",
                    marginTop: 6,
                    marginRight: 6,
                  }}
                />{" "}
                <Typography
                  mt="10px"
                  color="#8C8C8C"
                  fontSize="13px"
                  fontWeight="400"
                >
                  {order?.to_address}
                </Typography>
              </Box>
            </div>
            <Box sx={{ textAlign: "end" }}>
              <Typography whiteSpace="nowrap">
                {priceFromatter(order?.total_cost)} {t("sum")}
              </Typography>
              <Typography
                mt="10px"
                color="#8C8C8C"
                fontSize="13px"
                fontWeight="400"
              >
                {dayjs(order?.time).format("hh:mm")}
              </Typography>
            </Box>
          </Box>
        ))}
      </ListWrapper>
      {!todaysOrders?.isLoading &&
        (!todaysOrders?.data?.orders ||
          todaysOrders?.data?.orders?.length === 0) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              flexDirection: "column",
              color: "#8C8C8C",
              padding: "20px",
            }}
          >
            <Typography fontSize={18} fontWeight={500}>
              {t("no trips yet")}
            </Typography>
          </Box>
        )}

      {todaysOrders?.isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Wrapper>
  );
};

export default LastTrips;
