import axios from "axios";
import queryClient from "../configs/react-query.config";
import { logout, resetAuthSlice } from "../redux/slices/auth-slice";
import { addError, resetModalSlice } from "../redux/slices/modal-slice";
import { resetOrderSlice } from "../redux/slices/order-slice";
import { resetRouteSlice } from "../redux/slices/route-slice";
import store from "../redux/store";
import { loadState } from "../utils/storage";
import i18n from "../i18n";

const baseURL = import.meta.env.VITE_API_URL;
const API_KEY = import.meta.env.VITE_API_KEY;

const request = {
  private: axios.create({ baseURL, withCredentials: true }),
  public: axios.create({ baseURL }),
};

request.private.interceptors.request.use(
  (config) => {
    const accessToken = loadState("auth");
    // const { i18n } = useTranslation();

    let myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 12);

    if (accessToken) {
      config.headers = {
        ...config.headers,
        "Cache-Control": "no-cache",
        "API-KEY": API_KEY,
        SYSTEM: "web",
        Authorization: `Bearer ${store.getState().auth.token}`,
        "APP-LANGUAGE": store.getState().auth.language || i18n.language,
        // 'APP-VERSION': '0.1',
        // LANGUAGE: 'ru',
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

request.public.interceptors.request.use(
  (config) => {
    // const { i18n } = useTranslation();

    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      "API-KEY": API_KEY,
      SYSTEM: "web",
      "APP-LANGUAGE": store.getState().auth.language || i18n.language,
      // 'APP-VERSION': '0.1',
      // LANGUAGE: 'ru',
    };
    return config;
  },
  (error) => {
    store.dispatch(addError({ error_type: error?.response?.data.error.type }));
    return Promise.reject(error);
  }
);

request.private.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      !window?.location?.pathname?.startsWith("/mobile")
    ) {
      localStorage.clear();
      store.dispatch(resetAuthSlice());
      store.dispatch(resetModalSlice());
      store.dispatch(resetOrderSlice());
      store.dispatch(resetRouteSlice());
      store.dispatch(logout());
      queryClient.invalidateQueries();
      window.location.replace("/");
      localStorage.removeItem("user");
      return Promise.reject(error?.response?.data);
    }

    store.dispatch(
      addError({
        error_type: error?.response?.data.error.type,
      })
    );

    return Promise.reject(error?.response?.data);
  }
);

export default request;
