import { useInfiniteQuery } from "react-query";
import { useAppSelector } from "../../redux/hook";
import request from "../request";
import { useGetClientProfile } from "../queries/use-get-client-profile";
import { CorpClientsType } from "./use-get-corp-clients";

export const useGetCorpInfiniteEmployees = (search?: string) => {
  const { isAuthenticated, corp_id } = useAppSelector((state) => state.auth);

  const fetchProjects = ({ pageParam = 0 }) =>
    request.private
      .get<{ clients: Array<CorpClientsType>; next?: boolean | null }>(
        !!search?.length
          ? `/v1/corporates/${corp_id}/clients/search?query=${search}&page=${
              pageParam ?? 1
            }`
          : `/v1/corporates/${corp_id}/clients?page=${pageParam ?? 0}`
      )
      .then((res) => res.data);

  return useInfiniteQuery(["order-history-infinite", search], fetchProjects, {
    getNextPageParam: (lastPage, pages) =>
      lastPage?.next ? pages.length : undefined,
    enabled: isAuthenticated && !!corp_id,
  });
};
