import React, { useEffect, useCallback, memo } from "react";
import {
  CloseButton,
  ModalCard,
  ModalChildrenWrapper,
  ModalOverlay,
} from "./modal.style";
import { CloseIcon } from "../icons/close-icon";
import { ModalProps } from "./modal.types";
import { createPortal } from "react-dom";

export const Modal: React.FC<ModalProps> = memo(
  ({ isOpen, onClose, width, children, style, overlayStyle, closeIcon }) => {
    const closeEvent = useCallback((e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    }, []);

    useEffect(() => {
      if (isOpen) {
        document.addEventListener("keyup", closeEvent, true);
        document.body.style.overflowY = "hidden";
      } else {
        document.removeEventListener("keyup", closeEvent, true);
        document.body.style.overflowY = "unset";
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const root = document.querySelector("#root");

    return isOpen && root
      ? createPortal(
          <ModalOverlay
            onClick={(event: any) => {
              if (event.target === event.currentTarget) {
                onClose();
              }
            }}
            style={{
              visibility: isOpen ? "visible" : "hidden",
              opacity: isOpen ? "1" : "0",
              zIndex: 1500,
              ...overlayStyle,
            }}
          >
            <ModalCard style={{ maxWidth: width, ...style }}>
              {closeIcon && (
                <CloseButton onClick={onClose}>
                  <CloseIcon />
                </CloseButton>
              )}
              <ModalChildrenWrapper>{children}</ModalChildrenWrapper>
            </ModalCard>
          </ModalOverlay>,
          root
        )
      : null;
  }
);
