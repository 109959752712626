import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Radio from "../../../../components/radio/radio";
import queryClient from "../../../../configs/react-query.config";
import { Languages } from "../../../../lib/i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { setLanguage } from "../../../../redux/slices/auth-slice";
import { useEditClientLanguage } from "../../../../services/mutations/use-edit-client-language";
import { useGetClientLanguage } from "../../../../services/queries/use-get-client-language";
import useChangeLanguage from "../../../../hooks/use-change-language";

interface LanguagesType {
  languages: {
    label: string;
    value: string;
    icon: any;
  }[];
}

const LanguageControlInner: React.FC<LanguagesType> = ({ languages }) => {
  const appDispatch = useAppDispatch();
  const { isAuthenticated, language } = useAppSelector((state) => state.auth);
  const { i18n } = useTranslation();
  const changeLanguage = useChangeLanguage();

  const { data } = useGetClientLanguage();
  const { isLoading } = useEditClientLanguage();

  useEffect(() => {
    if (language !== data?.lang && !isLoading && isAuthenticated) {
      data?.lang && changeLanguage(data?.lang);
    }
  }, [data]);

  const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    changeLanguage(e.target.value);

  return (
    <Stack spacing={0.25} padding="4px" minWidth="215px">
      {!isLoading ? (
        languages.map((lang) => (
          <Radio
            key={lang.value}
            onChange={handleLanguageChange}
            checked={i18n.language === lang.value}
            label={
              <Stack alignItems="center" direction="row" spacing={2}>
                {lang.icon}
                <Typography variant="body1">{lang.label}</Typography>
              </Stack>
            }
            name="lang"
            value={lang.value}
          />
        ))
      ) : (
        <>
          <Box sx={{ width: "100%", height: "140px", display: "flex" }}>
            <CircularProgress sx={{ m: "auto" }} />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default LanguageControlInner;
