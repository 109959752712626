import { useMutation } from "react-query";
import request from "../request";
import dayjs from "dayjs";
import { useAppSelector } from "../../redux/hook";

const useGetCorpReports = () => {
  const corp_id = useAppSelector((state) => state.auth.corp_id);

  return useMutation({
    mutationFn: (date_range: { from: string; to: string }) => {
      const params = new URLSearchParams();
      if (date_range?.from)
        params.append(
          "from",
          dayjs(date_range.from).format("YYYY-MM-DDTHH:mm:ss")
        );
      if (date_range?.to)
        params.append("to", dayjs(date_range.to).format("YYYY-MM-DDTHH:mm:ss"));

      return request.private
        .get<string>(`/v1/corporates/${corp_id}/reports?${params.toString()}`, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          // Convert response to a Blob
          const blob = new Blob([res.data], {
            type: res.headers["content-type"] || "application/octet-stream",
          });

          // Create a File object
          const file = new File([blob], "reports.xlsx", { type: blob.type });

          return file;
        });
    },
  });
};

export default useGetCorpReports;
