const findBounds = (points: number[][]) => {
  let n = points.length;
  if (n === 0) {
    return [];
  }
  let d = points[0].length;
  let lo = points[0].slice();
  let hi = points[0].slice();
  for (let i = 1; i < n; ++i) {
    let p = points[i];
    for (let j = 0; j < d; ++j) {
      let x = p[j];
      lo[j] = Math.min(lo[j], x);
      hi[j] = Math.max(hi[j], x);
    }
  }
  return [lo, hi];
};

export default findBounds;
