import { useQuery } from "react-query";
import request from "../request";
import { useAppSelector } from "../../redux/hook";

const useGetCorpProfile = () => {
  const { corp_id, isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["corp-profile", corp_id],
    queryFn: () =>
      request.private
        .get<{
          id: number;
          name: string;
          balance: number;
          self_created: boolean;
          commission_rate: null;
          created_date: string; // "2015-11-11T12:30:00Z"
          clients_count: number;
        }>(`v1/corporates/${corp_id}/profile`)
        .then((res) => res.data),
    enabled: !!corp_id && isAuthenticated,
  });
};

export default useGetCorpProfile;
