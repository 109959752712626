import { Box, ButtonBase, Drawer, Typography } from "@mui/material";
import { bgcolor, borderColor } from "@mui/system";
import { X } from "lucide-react";
import React, { ReactNode } from "react";

const grayDrawer = {
  bgcolor: "#F3F3F3",
  borderColor: "#E7E7E7",
};

const whiteDrawer = {
  bgcolor: "#fff",
  borderColor: "#F9F9F9",
};

const drawerColors = {
  gray: grayDrawer,
  white: whiteDrawer,
};

interface MobileDrawerProps extends React.ComponentProps<typeof Drawer> {
  children?: any;
  drawerTitle?: any;
  leftTitle?: boolean;
  color?: "gray" | "white";
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({
  anchor = "bottom",
  drawerTitle = "Drawer Title",
  leftTitle = false,
  color = "white",
  PaperProps,
  children,
  ...props
}) => {
  return (
    <Drawer
      PaperProps={{
        ...PaperProps,
        sx: {
          borderRadius: "20px 20px 0 0",
          padding: "0px 16px",
          maxHeight: "85vh",
          bgcolor: drawerColors[color].bgcolor,
          pb: 2,
          ...PaperProps?.sx,
          minHeight: "20vh",
        },
      }}
      anchor={anchor}
      {...props}
    >
      <Box
        sx={{
          minHeight: "56px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: `1px solid ${drawerColors[color].borderColor}`,
          bgcolor: drawerColors[color].bgcolor,
          position: "sticky",
          top: 0,
          zIndex: 10,
        }}
      >
        <Box
          sx={
            !leftTitle
              ? {
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }
              : {}
          }
        >
          <Typography variant="h2" fontSize={17} fontWeight={500}>
            {drawerTitle}
          </Typography>
        </Box>
        <ButtonBase
          onClick={(event) => props?.onClose?.(event, "backdropClick")}
          style={{ color: "#C0C0C0", marginLeft: "auto" }}
        >
          <X />
        </ButtonBase>
      </Box>
      {children}
    </Drawer>
  );
};

export default MobileDrawer;
