import { IconType } from "../../types/utility.types";

const MapPin: IconType = (props) => (
  <svg
    width="50"
    height="74"
    viewBox="0 0 50 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9864 61.5083C26.9864 55.289 32.0244 50.3492 37.2987 47.0537C44.0666 42.825 48.5758 35.2574 48.5758 26.6254C48.5758 13.3705 37.9434 2.62537 24.8277 2.62537C11.712 2.62537 1.07959 13.3705 1.07959 26.6254C1.07959 35.5653 5.9163 43.3635 13.0894 47.4934C18.5232 50.6218 23.748 55.5386 23.748 61.8086V71.9152C23.748 72.8094 24.473 73.5344 25.3672 73.5344C26.2615 73.5344 26.9864 72.8094 26.9864 71.9152V61.5083Z"
      fill={props.color || "#4B89FA"}
    />
    <ellipse cx="24.8277" cy="26.6253" rx="9.71514" ry="9.81818" fill="white" />
    <defs>
      <filter
        id="filter0_f_175_163293"
        x="17.7334"
        y="0.272552"
        width="15.4471"
        height="3.17121"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.409195"
          result="effect1_foregroundBlur_175_163293"
        />
      </filter>
    </defs>
  </svg>
);

export default MapPin;
