import { IconType } from "../../types/utility.types";

const DocumentIcon: IconType = ({ color = "black", ...props }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.75 3V7C14.75 7.26522 14.8554 7.51957 15.0429 7.70711C15.2304 7.89464 15.4848 8 15.75 8H19.75"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 17H15.75M9.75 13H15.75M17.75 21H7.75C7.21957 21 6.71086 20.7893 6.33579 20.4142C5.96071 20.0391 5.75 19.5304 5.75 19V5C5.75 4.46957 5.96071 3.96086 6.33579 3.58579C6.71086 3.21071 7.21957 3 7.75 3H14.75L19.75 8V19C19.75 19.5304 19.5393 20.0391 19.1642 20.4142C18.7891 20.7893 18.2804 21 17.75 21Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentIcon;
