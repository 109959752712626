import React from "react";
import { IconType } from "../../types/utility.types";

const CirclePinNotTransparentIcon: IconType = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.6482 13.6485C20.6482 17.3204 17.6716 20.297 13.9997 20.297C10.3279 20.297 7.35124 17.3204 7.35124 13.6485C7.35124 9.97668 10.3279 7.00004 13.9997 7.00004C17.6716 7.00004 20.6482 9.97668 20.6482 13.6485Z"
      fill="white"
      stroke="#5B5A57"
      strokeWidth="2.33333"
    />
    <ellipse
      cx="14.0004"
      cy="13.6486"
      rx="2.87927"
      ry="2.87927"
      fill="#5B5A57"
    />
  </svg>
);

export default CirclePinNotTransparentIcon;
