import { useMutation } from "react-query";
import request from "../request";
import queryClient from "../../configs/react-query.config";

const useDeleteCorpClient = () => {
  return useMutation({
    mutationFn: (client_id: number) =>
      request.private
        .delete(`/v1/corporates/${client_id}/clients`)
        .then((res) => res.data),
    onSuccess: () => {
      queryClient?.invalidateQueries(["corp-clients"]);
    },
  });
};

export default useDeleteCorpClient;
