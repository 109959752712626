import { useQuery } from "react-query";
import request from "../request";
import { useAppSelector } from "../../redux/hook";

export interface CorpOrderType {
  id: number;
  time: string; // "2024-10-10T14:45:26Z"
  client_name: string;
  client_phone: string;
  from_address?: string;
  to_address?: string;
  total_cost: number;

  icon?: string;
}

const useGetCorpOrders = () => {
  const { corp_id, isAuthenticated } = useAppSelector((state) => state.auth);

  return useQuery({
    queryKey: ["corp-orders", corp_id],
    queryFn: () =>
      request.private
        .get<{ orders: Array<CorpOrderType> }>(
          `/v1/corporates/${corp_id}/orders`
        )
        .then((res) => res.data),
    enabled: !!corp_id && isAuthenticated,
  });
};

export default useGetCorpOrders;
