import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { Modal } from "../../modal/modal";
import { Box, Typography } from "@mui/material";
import Button from "../../buttons/button";
import { useTranslation } from "react-i18next";
import { ButtonProps } from "../../buttons/button.types";

interface ConfirmDialogType {
  isOpen?: boolean;
  title?: any;
  description?: any;
  closeBtnLabel?: any;
  confirmBtnLabel?: any;
  closeBtnProps?: ButtonProps;
  confirmBtnProps?: ButtonProps;
}

const ConfirmDialog = createContext<
  (choice: ConfirmDialogType) => Promise<boolean>
>(() => new Promise(() => false));

export function ConfirmDialogProvider({ children }: { children: any }) {
  const { t } = useTranslation();

  const [state, setState] = useState<ConfirmDialogType>({
    isOpen: false,
    closeBtnLabel: undefined,
    confirmBtnLabel: undefined,
    description: undefined,
  });
  const fn = useRef<(choice: boolean) => void>();

  const confirm = useCallback(
    (data: ConfirmDialogType): Promise<boolean> => {
      return new Promise<boolean>((resolve) => {
        setState({ ...data, isOpen: true });
        fn.current = (choice) => {
          resolve(choice);
          setState({ isOpen: false });
        };
      });
    },
    [setState]
  );

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      <Modal
        isOpen={!!state.isOpen}
        onClose={(event: any) => {
          fn.current && fn?.current(false);
          state.closeBtnProps?.onClick && state.closeBtnProps?.onClick(event);
        }}
        closeIcon={false}
      >
        {typeof state.description !== "string" &&
        state.description !== undefined ? (
          state.description
        ) : (
          <Typography align="center" mx="auto">
            {state.description || t("Are you sure?")}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",
            mt: 2.5,
          }}
        >
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            fullWidth
            {...state.closeBtnProps}
            onClick={(event: any) => {
              fn.current && fn?.current(false);
              state.closeBtnProps?.onClick &&
                state.closeBtnProps?.onClick(event);
            }}
          >
            {state.closeBtnLabel || t("cancellation")}
          </Button>
          <Button
            variant="contained"
            size="medium"
            fullWidth
            {...state.confirmBtnProps}
            onClick={(event: any) => {
              fn.current && fn?.current(true);
              state.confirmBtnProps?.onClick &&
                state.confirmBtnProps?.onClick(event);
            }}
          >
            {state.confirmBtnLabel || t("yes")}
          </Button>
        </Box>
      </Modal>
    </ConfirmDialog.Provider>
  );
}

export default function useConfirm(): (
  choice: ConfirmDialogType
) => Promise<boolean> {
  return useContext(ConfirmDialog);
}
