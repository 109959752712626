import { useMutation } from "react-query";
import request from "../request";

export const useSentSMS = () =>
  useMutation(
    ({
      phone_number,
      telegram = false,
    }: {
      phone_number: string;
      telegram?: boolean;
    }) => {
      console.log("phone_number", phone_number);

      return request.public
        .post(
          `v1/register/${phone_number}${
            telegram ? "?provider_type=telegram" : ""
          }`
        )
        .then((res) => res.data);
    }
  );
