import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import Button from "../../buttons/button";
import { Modal } from "../../modal/modal";

const MessageModal: React.FC<{
  isOpen: boolean;
  close: () => void;
  buttonText?: any;
  message: any;
}> = ({ isOpen, close, buttonText, message }) => (
  <Modal
    style={{ width: "450px" }}
    isOpen={isOpen}
    onClose={close}
    closeIcon={false}
  >
    <Typography mb={2} textAlign="center">
      {message}
    </Typography>
    <Button onClick={close}>{buttonText ? buttonText : "Ok"}</Button>
  </Modal>
);

export default MessageModal;
