import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkBtn = styled(Link)`
  padding: ${({ theme }) => theme.spacing(1.25)};
  display: flex;
  align-items: center;
`;

export { LinkBtn };
